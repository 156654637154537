import { IRequestState } from '~utils/hn-api';

export enum NotificationPreferencesEnum {
    clothesPickedUp = 'clothesPickedUp',
    clothesReturned = 'clothesReturned',
    generalNote = 'generalNote',
    invoice = 'invoice',
    pickupPrompt = 'pickupPrompt',
    pickupReminder = 'pickupReminder',
    priorToArrival = 'priorToArrival',
    notesExpire = 'notifyInstructionsExpiration',
    tips = 'tips',
}

export type PreferenceType = 'email' | 'text' | 'popup';

export interface IPreferencePayload {
    index: number;
    type: PreferenceType;
    value: boolean;
}

export interface INotificationPreferencesRow {
    name: NotificationPreferencesEnum;
    enabledBySubscriber?: boolean;
    emailTextTitle: string;
    popupTitle?: string;
    email?: boolean;
    text?: boolean;
    popup?: boolean;
}

export interface IUserNotificationPreferences {
    FK_PK_usersID: number;
    PK_notificationPreferencesID: number;
    clothesPickedUpEmail: boolean;
    clothesPickedUpPush: boolean;
    clothesPickedUpText: boolean;
    clothesReturnedEmail: boolean;
    clothesReturnedPush: boolean;
    clothesReturnedText: boolean;
    generalNoteEmail: boolean;
    generalNotePush: boolean;
    generalNoteText: boolean;
    invoiceEmail: boolean;
    invoicePush: boolean;
    invoiceText: boolean;
    pickupPromptEmail: boolean;
    pickupPromptPush: boolean;
    pickupPromptText: boolean;
    pickupReminderEmail: boolean;
    pickupReminderPush: boolean;
    pickupReminderText: boolean;
    priorToArrivalEmail: boolean;
    priorToArrivalPush: boolean;
    priorToArrivalText: boolean;
    tipsEmail: boolean;
    tipsPopup: boolean;
    tipsPush: boolean;
    tipsText: boolean;
    tipsEnabled: boolean;
    notifyInstructionsExpirationEmail: boolean;
    notifyInstructionsExpirationPush: boolean;
    notifyInstructionsExpirationText: boolean;
    [key: string]: number | boolean;
}

export interface INotificationPreferencesEvent {
    emailText: string;
    popup?: string;
    checkIfEnabled?: boolean;
}

export interface INotificationPreferencesState extends IRequestState {
    data: INotificationPreferencesRow[];
    initialValues: IUserNotificationPreferences;
}

export interface NotificationPreferencesPayload {
    linkType: number;
    payload: {
        email: string;
        userId: number;
    };
}

export const unsubscribedEmailPreferences = {
    priorToArrivalEmail: false,
    pickupPromptEmail: false,
    pickupReminderEmail: false,
    clothesPickedUpEmail: false,
    clothesReturnedEmail: false,
    invoiceEmail: false,
    generalNoteEmail: false,
    notifyInstructionsExpirationEmail: false,
} as IUserNotificationPreferences;

const NotificationPreferences = new Map<
    NotificationPreferencesEnum,
    INotificationPreferencesEvent
>();

NotificationPreferences.set(NotificationPreferencesEnum.pickupPrompt, {
    emailText: 'Remind me the day before to schedule a pickup.',
})
    .set(NotificationPreferencesEnum.pickupReminder, {
        emailText: 'Remind me the night before the pickup to leave my clothes out.',
    })
    .set(NotificationPreferencesEnum.priorToArrival, {
        emailText: "Notify me shortly before the driver's arrival.",
    })
    .set(NotificationPreferencesEnum.clothesPickedUp, {
        emailText: 'Let me know when my clothes have been picked up.',
    })
    .set(NotificationPreferencesEnum.clothesReturned, {
        emailText: 'Let me know when my clothes have been dropped off.',
    })
    .set(NotificationPreferencesEnum.invoice, { emailText: 'Send Invoices.' })
    .set(NotificationPreferencesEnum.generalNote, {
        emailText: 'Holiday Schedule Changes, etc..',
    })
    .set(NotificationPreferencesEnum.notesExpire, {
        emailText: 'Notify me shortly before instruction expirations.',
    })
    .set(NotificationPreferencesEnum.tips, {
        emailText: 'Include "Driver Tip" prompt.',
        popup: 'Show me the "Add a Tip" pop-up.',
        checkIfEnabled: true,
    });

export default NotificationPreferences;
