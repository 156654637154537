import CloseIcon from './close-icon';
import MenuIcon from './menu-icon';
import ReferFriendIcon from './refer-friend-icon';
import AddressIcon from './address-icon';
import CreditCardIcon from './credit-card-icon';
import LaundryIcon from './laundry-icon';
import LogoutIcon from './logout-icon';
import PersonalInfoIcon from './personal-info-icon';
import SettingsIcon from './settings-icon';
import HoldIcon from './hold-icon';
import PromoIcon from './promo-icon';
import ArrowRightIcon from './arrow-right-icon';
import ArrowLeftIcon from './arrow-left-icon';
import HideIcon from './hide-icon';
import CalendarIcon from './calendar-icon';
import AddCircleOutlineRoundedIcon from './add-circle-outline-rounded-icon';
import AddIcon from './add-icon';
import PasswordIcon from './password-icon';
import NotificationIcon from './notification-icon';
import InvoiceIcon from './invoice-icon';
import SlidersIcon from './sliders-icon';
import EmailIcon from './email-icon';
import InstructionsIcon from './instructions-icon';
import HandHoldingDollarIcon from './hand-holding-dollar-icon';
import HnLogoVertical from './hn-logo-vertical';
import CircleDollarIcon from './circle-dollar-icon';

export {
    CloseIcon,
    MenuIcon,
    ReferFriendIcon,
    AddressIcon,
    CreditCardIcon,
    LaundryIcon,
    LogoutIcon,
    PersonalInfoIcon,
    SettingsIcon,
    HoldIcon,
    PromoIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    HideIcon,
    CalendarIcon,
    AddCircleOutlineRoundedIcon,
    AddIcon,
    PasswordIcon,
    NotificationIcon,
    InvoiceIcon,
    SlidersIcon,
    EmailIcon,
    InstructionsIcon,
    HandHoldingDollarIcon,
    HnLogoVertical,
    CircleDollarIcon,
};
